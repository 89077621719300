//Link Scss
@import "color.scss";

@import "nav.scss";
@import "responsive.scss";

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap');



html{
    height: -webkit-fill-available;
}

body{
    font-family: 'Jost', sans-serif;
    letter-spacing: .5px;
    cursor: context-menu;
    overflow-x: hidden;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    main{
        width: 100%;
        overflow: hidden;
    }
    &.overflow-y-hidden{
        overflow-y: hidden !important;
    }
}

a{
    color: inherit;
    text-decoration: none;
    outline: none !important;
    &:hover{
        text-decoration: none;
        color: inherit;
    }
    &:visited{
        text-decoration: none;
        color: inherit; 
    }
    &:link{
        text-decoration: none;
        color: inherit; 
    }
    &:active{
        text-decoration: none;
        color: inherit; 
        outline: none !important;
        box-shadow: none;
    }
    &.page-link{
        &:focus{
            box-shadow: none;
        }
    }
}

.btn{
    outline: none !important;
    &:focus,&:active{
        outline: 0 !important;
        box-shadow: none !important;
    }
    &.show{
        background-color: $primary;
        color: #FFF!important;
        border-color: $primary;
    }
}

.btn-outline-primary{
    border-color: $primary;
    background-color: transparent;
    color: $primary !important;
    font-size: 14px;
    &:hover{
        background-color: $primary;
        color: #FFF!important;
        border-color: $primary;
    }
    &:active{
        background-color: $primary !important;
        color: #FFF!important;
        border-color: $primary !important;
    }
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active .show>.btn-outline-primary.dropdown-toggle{
    border-color: $primary;
    background-color: transparent;
    color: $primary; 
    &:hover{
        background-color: $primary;
        color: #FFF!important;
        border-color: $primary;
    }
}

.form-control{
    outline: none !important;
    &:focus{
        outline: none !important;
        box-shadow: none !important;
    }
}

header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 15;
    padding: 15px 0;
    &.bg-solid{
        position: fixed;
        background: rgba($white,.95);
        transition: .5s ease all;
        .logo{
            img{
                height: 40px;
                transition: .5s ease all;
            }
            .text{
				color: $black;
                transition: .1s ease all;
                h1{
                    font-size: 12px;
                    transition: .5s ease all;
                }
            }
        }
        .menu{
            top: 70px;
            ul{
                li{
                    a{
                        font-size: 14px;
						color: $black;
                        transition: .5s ease all;
                    }
                }
            }
        }
        .cage-nav{
            height: 35px;
            width: 35px;
            padding: 0;
            justify-content: center;
            align-items: center;
            transition: .5s ease all;
            .navTrigger{
                width: 28px;
                i{
                    background-color: $black;
                    height: 4px;
                    transition: .5s ease all;
                }
            }  
        }
    }
    &.page{
        position: relative;
        background: $white;
    }
    .logo{
        display: flex;
        align-items:center;
        img{
            height: 60px;
            margin-right: 7px;
        }
        .text{
            color: $white;
            font-family: 'DM Serif Display', serif;
            transition: .1s ease all;
            h1{
                margin: 0;
                font-size: 18px;
                font-weight: 900;
                letter-spacing: 1px;
                text-transform: uppercase;
                transition: .5s ease all;
            }
            h2{
                margin:0;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;
                transition: .5s ease all;
            }
        }
    }
    .menu{
        .container{
            padding: 0;
        }
        ul{
            display: flex;
            list-style: none;
            margin: 0;
            li{
                position: relative;
                z-index: 0;
                &.active{
                    a{
                        color: $secondary;
                    }
                }
                a{
					color: $white;
                    display: block;
                    padding: 8px 15px;
                    transition: .5s ease all;
                }
                ul{
                    display: none;
                    position: absolute;
                    background: $primary;
                    color: $white;
                    left: 0;
                    top: calc(100% - 2.5px);
                    width: 100%;
                    min-width: 180px;
                }
                &:hover{
                    a{
                        color: $primary;
                        transition: .5s ease all;
                    }
                    ul{
                        display: block;
                        z-index: 2;
						padding-left: 0;
                        li{
                            a{
                                color: $white;
                                &:hover{
                                    color: $secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.mobile-active{
            right: 0;
            transition: .5s ease all;
        }
    }
}

section.header-page{
    position: relative;
    width: 100%;
    height: 40vh;
    background: url('../images/bgparallax.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 2px solid $primary;
    &:before{
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 90%;
        background: linear-gradient(to bottom, rgba(0,0,0,.9) 0%, rgba(255,255,255,0) 100%);
    }
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($black, .5);
    }
    .text{
        position: absolute;
        bottom: 0;
        z-index: 2;
        color: $white;
        h1{
            font-family: 'DM Serif Display', serif;
        }
    }
}

section.page{
	padding: 60px 0;
	.sideMenu{
		background: $primary;
		padding: 5px 15px 15px;
		border-radius: 3px;
		ul{
			list-style: none;
			margin: 0;
			padding: 0;
			li{
				a{
					display: block;
					padding: 10px 15px;
					color: $white;
					border-bottom: 1px solid $white;
				}
				&.active{
					a{
						color: $secondary;
						border-color: $secondary;
					}
				}
				&:hover{
					transition: .5s ease all;
					a{
						color: $secondary;
						border-color: $secondary;
						transition: .5s ease all;
					}
				}
			}
		}
	}
	.share-view{
		margin-top: 30px;
        ul{
            list-style: none;
            padding: 0;
            display: flex;
            li{
                padding: 0 5px;
                &.facebook{
                    a{
                        background: #3b5998;
                    }
                }
                &.twitter{
                    a{
                        background: #00acee;
                    }
                }
                &.whatsapp{
                    a{
                        background: #4FCE5D;
                    }
                }
                &.view{
                    a{
                        background: $primary;
                    }
                }
                a{
                    display: flex;
                    min-width: 70px;
                    align-items: center;
                    color: $white;
                    padding: 0 2px;
                    border-radius: 3px;
                    font-size: 24px;
                    .text{
                        width: 100%;
                        font-size: 10px;
                        text-align: center;
                    }
                }
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
            }
        }
    }
	.newsPopular{
		h1{
			font-size: 18px;
			margin-bottom: 25px;
		}
		ul{
			list-style: none;
			padding: 0;
			margin: 0;
			li{
				display: flex;
				margin-bottom: 18px;
				.image{
					width: 30%;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: cover;
					border-radius: 3px;
				}
				.text{
					width: 70%;
					padding-left: 10px;
					h3{
						font-size: 14px;
						line-height: 1.5;
						max-width: 100%;
						max-height: 100%;
						white-space: pre-wrap;
						word-break: keep-all;
						text-overflow: ellipsis;
						overflow: hidden;
						margin-bottom: 4px;
						min-height: 16px;
						display: -webkit-box;
						-webkit-line-clamp: 2;
    					-webkit-box-orient: vertical;
					}
					a{
						font-size: 12px;
						display: block;
						color: $secondary;
						transition: .5s ease all;
						&:hover{
							color: $primary;
							transition: .5s ease all;
						}
					}
				}
			}
		}
	}
	.anounNew{
		h1{
			font-size: 18px;
			margin-bottom: 25px;
		}
		ul{
			list-style: none;
			margin: 0 0 30px 0;
			padding: 0;
			li{
				background: #F0F0F0;
				position: relative;
				padding: 15px 10px 15px 100px;
				margin-top: 10px;
				margin-bottom: 30px;
				h4{
					font-size: 14px;
					font-weight: bold;
					&:hover{
						color: $primary;
					}
				}
				p{
					font-size: 12px;
					margin: 0 0 8px 0;
				}
				.btn-read{
					font-size: 12px;
					&:hover{
						color: $primary;
					}
				}
				.date{
					position: absolute;
					left: 10px;
					top: -10px;
					background: $primary;
					width: 80px;
					height: 80%;
					padding: 10px;
					text-transform: uppercase;
					color: $white;
					text-align: center;
					&:before{
						content: '';
						width: 10px;
						height: 10px;
						border: 5px solid black;
						position: absolute;
						top: 0;
						left: -10px;
						border-left-color: transparent;
						border-top-color: transparent;
					}
					.box{
						position: relative;
						width: 100%;
						height: 100%;
						z-index: 3;
						overflow: hidden;
						h3{
							font-size: 12px;
							position: absolute;
							top: 50%;
							left: 50%;
							transform:translate(-50%,-50%);
							width: 100%;
							span{
								display: block;
								font-size: 24px;
							}
						}
					}
				}
			}
		}
	}
	ul.newsList{
		list-style: none;
		padding: 0;
		li{
			display: flex;
			margin-bottom: 30px;
			.image{
				width: 30%;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
				border-radius: 3px;
				background-color: $primary;
			}
			.text{
				width: 70%;
				min-height: 100px;
				padding-left: 10px;
				h3{
					font-size: 14px;
					line-height: 1.5;
					max-width: 100%;
					max-height: 100%;
					white-space: pre-wrap;
					word-break: keep-all;
					text-overflow: ellipsis;
					overflow: hidden;
					margin-bottom: 4px;
					min-height: 16px;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					font-weight: bold;
				}
				p{
					font-size: 14px;
					line-height: 1.5;
					max-width: 100%;
					max-height: 100%;
					white-space: pre-wrap;
					word-break: keep-all;
					text-overflow: ellipsis;
					overflow: hidden;
					margin-bottom: 4px;
					min-height: 16px;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					&.name{
						color: $primary;
					}
				}
				a{
					font-size: 12px;
					display: block;
					color: $secondary;
					transition: .5s ease all;
					&:hover{
						color: $primary;
						transition: .5s ease all;
					}
				}
			}
		}
	}
	.loadMore{
		width: 100%;
		text-align: center;
		button{
			border: 1px solid $primary;
			padding: 4px 15px;
			border-radius: 4px;
			background-color: $primary;
			color: $white;
			transition: .5s ease all;
			&:hover{
				background-color: $secondary;
				transition: .5s ease all;
			}
		}
	}
	ul.anounList{
		list-style: none;
		margin: 0 0 30px 0;
		padding: 0;
		li{
			background: #F0F0F0;
			position: relative;
			padding: 15px 10px 15px 100px;
			margin-top: 10px;
			margin-bottom: 30px;
			h4{
				font-size: 14px;
				font-weight: bold;
				&:hover{
					color: $primary;
				}
			}
			p{
				font-size: 12px;
				margin: 0 0 8px 0;
			}
			.btn-read{
				font-size: 14px;
				&:hover{
					color: $primary;
				}
			}
			.date{
				position: absolute;
				left: 10px;
				top: -10px;
				background: $primary;
				width: 80px;
				height: 80%;
				padding: 10px;
				text-transform: uppercase;
				color: $white;
				text-align: center;
				&:before{
					content: '';
					width: 10px;
					height: 10px;
					border: 5px solid black;
					position: absolute;
					top: 0;
					left: -10px;
					border-left-color: transparent;
					border-top-color: transparent;
				}
				.box{
					position: relative;
					width: 100%;
					height: 100%;
					z-index: 3;
					overflow: hidden;
					h3{
						font-size: 16px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform:translate(-50%,-50%);
						width: 100%;
						span{
							display: block;
							font-size: 32px;
						}
					}
				}
			}
		}
	}
	ul.extraList{
		list-style: none;
		padding: 0;
		li{
			.content{
				border: 1px solid $primary;
				border-radius: 8px;
				overflow: hidden;
				height: 100%;
				box-shadow: 0px 0 30px rgba(1, 41, 112, 0.2);
				transition: .5s ease all;
				position: relative;
				&:before{
					content: '';
					background: linear-gradient(135deg, rgba($primary,1) 70%, rgba($secondary,1) 100%);
					position: absolute;
					left: -100%;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: -1;
					transition: .5s ease all;
				}
				&:hover{
					transition: .5s ease all;
					color: $white;
					&:before{
						left: 0;
						transition: .5s ease all;
					}
				}
				.image{
					width: 100%;
					height: 100%;
					min-height: 100px;
					background-size: cover;
					background-position: center center;
					background-repeat: no-repeat;
				}
				h1.title{
					font-size: 24px;
					transition: .5s ease all;
					&:hover{
						color: $secondary;
						transition: .5s ease all;
					}
				}
				p{
					font-size: 14px;
					line-height: 1.5;
					max-width: 100%;
					max-height: 100%;
					white-space: pre-wrap;
					word-break: keep-all;
					text-overflow: ellipsis;
					overflow: hidden;
					margin-bottom: 4px;
					min-height: 16px;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.btn-link{
					i{
						transition: .5s ease all;
					}
					&:hover{
						color: $white;
						i{
							transition: .5s ease all;
							color: $secondary;
						}
					}
				}
			}
		}
	}
	ul.listImage{
		list-style: none;
		padding: 0;
		li{
			cursor: pointer;
		}
	}
}

section.contact{
	padding: 60px 0;
	h1{
		font-size: 24px;
	}
	.btn-submit{
		padding: 4px 15px;
		background: $primary;
		color: $white;
		border: 1px solid $primary;
		border-radius: 4px;
		transition: .5s ease all;
		&:hover{
			background: $secondary;
			transition: .5s ease all;
		}
	}
	ul.contact{
		list-style: none;
		padding: 0;
		li{
			a{
				display: block;
				padding: 3px 0;
				i{
					width: 30px;
					color: $secondary;
					transition: .5s ease all;
					&:hover{
						color: $primary;
						transition: .5s ease all;
					}
				}
				span{
					transition: .5s ease all;
					&:hover{
						color: $primary;
						transition: .5s ease all;
					}
				}
			}
		}
	}
}

section.slider{
	.swiper-container{
		width: 100%;
		height: 100vh;
		background: $primary;
		position: relative;
		.swiper-wrapper{
			&:before{
				content: '';
				position: absolute;
				z-index: 1;
				bottom: 40px;
				left: 80px;
				width: 100px;
				height: 1px;
				background: rgba($white,.9);
			}
			.swiper-slide{
				background-position: center center;
				background-size: cover;
				background-repeat: no-repeat;
				position: relative;
				display: flex;
				align-items: center;
				padding: 100px 40px 100px 100px;
				&:before{
					content: '';
					background: linear-gradient(180deg, rgba(0,0,0,.8) 0%, rgba(255,255,255,0) 100%);
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 80%;
					z-index: 2;
				}
				&:after{
					content: '';
					background: linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(255,255,255,0) 100%);
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 80%;
					z-index: 2;
				}
				&:nth-child(odd){
					text-align: left;
					justify-content: flex-start;
				}
				&:nth-child(even){
					text-align: right;
					justify-content: flex-end;
				}
				.description{
					position: relative;
					z-index: 3;
					width: 70%;
					.head-text{
						font-style: italic;
						color: rgba($white,.8);
						margin-bottom: 15px;
						font-weight: 300;
					}
					h1{
						color: $secondary;
						text-transform: uppercase;
						font-weight: 700;
						font-size: 64px;
						line-height: 60px;
						margin-bottom: 5px;
						font-family: 'DM Serif Display', serif;
					}
					h2{
						color: $white;
						font-weight: 300;
						font-size: 46px;
						line-height: 46px;
						margin-bottom: 15px;
					}
					.btn-slide{
						background: $primary;
						border: 1px solid $secondary;
						color: $white;
						padding: 4px 25px;
						border-radius: 15px;
						margin-bottom: 15px;
						transition: .5s ease all;
						&:hover{
							transition: .5s ease all;
							opacity: .8;
						}
					}
					.foot-text{
						text-transform: uppercase;
						color: rgba($white,.8);
						letter-spacing: 2px;
						font-weight: 300;
					}
				}
			}
		}
		.vertical-info{
			position: absolute;
			top: 50%;
			left: 50px;
			padding: 10px 100px 10px 50px;
			z-index: 3;
			width: 100vh;
			transform: translate(-50%, -50%) rotate(270deg);
			display: flex;
			justify-content: space-between;
			.socmed{
				a{
					display: inline-block;
					padding-left: 15px;
					color: rgba($white,.9);
					&:first-child(){
						padding-left: 0;
					}
					i{
						transform: rotate(90deg);
					}
				}
			}
			&:after{
				content: 'Ikuti Kami';
				text-transform: uppercase;
				letter-spacing: 5px;
				position: absolute;
				top: 50%;
				left: calc(45% + 30vh);
				transform: translate(-50%, -50%);
				color: rgba($white,.9);
			}
			&:before{
				content: '';
				position: absolute;
				top: 50%;
				left: 45%;
				transform: translate(-50%, -50%);
				width: 30vh;
				height: 1px;
				background: rgba($white,.9);
			}
		}
		.swiper-pagination{
			bottom: 20px;
			left: 200px;
			height: 50px;
			width: 50px;
			color: $white;
			display: flex;
			justify-content: space-evenly;
			align-items: flex-end;
			.swiper-pagination-current{
				font-size: 52px;
				line-height: 52px;
				color: $secondary;
			}
		}
		.swiper-navigation{
			position: absolute;
			z-index: 3;
			bottom: 20px;
			right: 45px;
			color: $white;
			display: flex;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 18px;
			.slide-button-prev{
				margin-right: 15px;
				&.swiper-button-disabled{
					opacity: .7;
				}
			}
			.slide-button-next{
				margin-left: 15px;
				&.swiper-button-disabled{
					opacity: .7;
				}
			}
		}
	}
}

section.intro{
	background-image: url('../images/pattern.png');
    background-position: center center;
    background-size: auto 100%;
    padding: 60px 0;
    .content{
        position: relative;
        h1.title{
            font-family: 'DM Serif Display', serif;
            font-size: 24px;
        }
    }
    .image{
        width: calc(100% - 30px);
        margin: 15px;
        height: calc(100% - 30px);
        min-height: 450px;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
        border-radius: 30px 30px 0 30px;
        position: relative;
        z-index: 3;
        &:before{
            content: '';
            position: absolute;
            bottom: -15px;
            right: -15px;
            border-radius: 0 15px 15px 0;
            width: 15px;
            height: 50%;
            background: $primary;
            z-index: -1;
        }
        &:after{
            content: '';
            position: absolute;
            bottom: -15px;
            right: -15px;
            border-radius: 0 0 15px 15px;
            width: 100%;
            height: 15px;
            background: $primary;
            z-index: -1;
        }
    }
}

section.smartSchool{
	margin: 60px 0 0;
	padding: 90px 0;
	background-image: url('../images/bgparallax.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	position: relative;
	&::before{
		content: '';
		position: absolute;
		background: rgba($white,.7);
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
	}
	h1.title{
		font-family: 'DM Serif Display', serif;
		font-size: 24px;
		text-align: center;
		margin-bottom: 40px;
	}
	.linkCard{
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 2;
		background: rgba($primary,.9);
		color: $white;
		text-align: center;
		justify-content: center;
		border-radius: 4px;
		padding: 15px;
		height: 100%;
		overflow: hidden;
		&::before{
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			background-image: url('../images/logo.png');
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center center;
			height: 100%;
			width: 50%;
			z-index: -1;
			opacity: .1;
		}
		h1{
			font-size: 22px;
		}
		p{
			font-size: 12px;
			margin-bottom: 0;
		}
		&.disabled{
			filter: grayscale(1);
			pointer-events: none;
		}
	}
}

section.highlight{
	padding: 60px 0;
    h1.title{
        font-family: 'DM Serif Display', serif;
        font-size: 32px;
    }
	a.btn-another p{
		margin-bottom: 48px;
		color: $primary;
	}
	ul.prestasi{
		list-style: none;
		padding: 0 0 30px 0;
		margin: 0;
		li{
			position: relative;
			display: flex;
			background-color: #F0F0F0;
			margin-bottom: 25px;
			transition: .5s ease all;
			&::before{
				content: '';
				position: absolute;
				background: $primary;
				top: -10px;
				height: 10px;
				width: 80%;
				border-radius: 0 20px 0 0;
				z-index: 2;
			}
			&::after{
				content: '';
				position: absolute;
				background: $primary2;
				top: -10px;
				left: calc(80% - 10px);
				height: 10px;
				width: 20px;
				border-radius: 0 20px 0 0;
			}
			.image{
				width: 23%;
			}
			.text{
				width: 77%;
				padding: 15px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				.title{
					font-weight: bold;
					font-size: 14px;
				}
				.winner{
					color: $secondary;
					margin-bottom: 8px;
					font-size: 13px;
				}
			}
			a{
				font-size: 14px;
				&:hover{
					color: $primary;
				}
			}
		}
	}
	ul.pengumuman{
		list-style: none;
		margin: 0 0 30px 0;
		padding: 0;
		li{
			background: #F0F0F0;
			position: relative;
			padding: 15px 10px 15px 100px;
			margin-top: 10px;
			margin-bottom: 30px;
			h4{
				font-size: 14px;
				font-weight: bold;
				&:hover{
					color: $primary;
				}
			}
			p{
				font-size: 12px;
				margin: 0 0 8px 0;
			}
			.btn-read{
				font-size: 14px;
				&:hover{
					color: $primary;
				}
			}
			.date{
				position: absolute;
				left: 10px;
				top: -10px;
				background: $primary;
				width: 80px;
				height: 80%;
				padding: 10px;
				text-transform: uppercase;
				color: $white;
				text-align: center;
				&:before{
					content: '';
					width: 10px;
					height: 10px;
					border: 5px solid black;
					position: absolute;
					top: 0;
					left: -10px;
					border-left-color: transparent;
					border-top-color: transparent;
				}
				.box{
					position: relative;
					width: 100%;
					height: 100%;
					z-index: 3;
					overflow: hidden;
					h3{
						font-size: 16px;
						position: absolute;
						top: 50%;
						left: 50%;
						transform:translate(-50%,-50%);
						width: 100%;
						span{
							display: block;
							font-size: 32px;
						}
					}
				}
			}
		}
	}
}

section.news{
    padding: 60px 0;
    h1.title{
        font-family: 'DM Serif Display', serif;
        font-size: 32px;
    }
    .content{
		position: relative;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
        border-radius: 15px;
        height: 100%;
        transition: .5s ease all;
        &:hover{
            box-shadow: 0px 0 30px rgba(1, 41, 112, 0.4);
            transition: .5s ease all;
        }
        .image{
            width: 100%;
            height: 40vh;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 15px 15px 0 0;
            overflow: hidden;
            position: relative;
            .date{
                background: $primary;
                display: inline-block;
                color: $white;
                padding: 3px 8px;
                font-size: 12px;
                position: absolute;
                bottom: 10px;
                left: 15px;
            }
        }
        .description{
            padding: 15px;
            h1.title{
                font-size: 24px;
                transition: .5s ease all;
                &:hover{
                    color: $secondary;
                    transition: .5s ease all;
                }
            }
			p{
				margin-bottom: 40px;
			}
            .btn-link{
				position: absolute;
				left: 15px;
				bottom: 15px;
                i{
                    transition: .5s ease all;
                }
                &:hover{
					color: $primary;
                    i{
                        transition: .5s ease all;
                        color: $secondary;
                    }
                }
            }
        }
    }
}

section.extra{
    padding: 60px 0;
    h1.title{
        font-family: 'DM Serif Display', serif;
        font-size: 32px;
    }
    .content{
        border: 1px solid $primary;
        border-radius: 8px;
        overflow: hidden;
        height: 100%;
        box-shadow: 0px 0 30px rgba(1, 41, 112, 0.2);
        transition: .5s ease all;
        position: relative;
        &:before{
            content: '';
            background: linear-gradient(135deg, rgba($primary,1) 70%, rgba($secondary,1) 100%);
            position: absolute;
            left: -100%;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            transition: .5s ease all;
        }
        &:hover{
            transition: .5s ease all;
            color: $white;
            &:before{
                left: 0;
                transition: .5s ease all;
            }
        }
        .image{
            width: 100%;
            height: 100%;
            min-height: 100px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
        h1.title{
            font-size: 24px;
            transition: .5s ease all;
            &:hover{
                color: $secondary;
                transition: .5s ease all;
            }
        }
        .btn-link{
            i{
                transition: .5s ease all;
            }
            &:hover{
                color: $white;
                i{
                    transition: .5s ease all;
                    color: $secondary;
                }
            }
        }
    }
}

section.gallery{
	padding: 60px 0;
    h1.title{
        font-family: 'DM Serif Display', serif;
        font-size: 32px;
    }
	.galeriSlide{
		.swiper-wrapper{
			.swiper-slide{
				display: block;
				overflow: hidden;
				border-radius: 8px;
			}
		}
	}
	.loadMore{
		width: 100%;
		text-align: center;
		button{
			border: 1px solid $primary;
			padding: 4px 15px;
			border-radius: 4px;
			background-color: $primary;
			color: $white;
			transition: .5s ease all;
			&:hover{
				background-color: $secondary;
				transition: .5s ease all;
			}
		}
	}
}

section.ppdb{
    padding: 60px 0;
    .content{
        background-color: $primary;
        text-align: center;
        padding: 60px 15px;
        color: $white;
        border-radius: 30px;
        h1.title{
            font-family: 'DM Serif Display', serif;
            font-size: 32px;
        }
        .btn-link{
            border: 1px solid $white;
            background-color: rgba($white, .08);
			&:hover{
				background-color: rgba($secondary,1);
				color: $white;
			}
        }
    }
}

section.staff{
	padding: 60px 0;
	ul{
		list-style: none;
		padding: 0;
		li{
			.content{
				position: relative;
				width: 100%;
				height: 100%;
				padding: 10px;
				border-radius: 5px;
				box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
				transition: .5s ease all;
				&:hover{
					box-shadow: 0px 0 30px rgba(1, 41, 112, 0.4);
					transition: .5s ease all;
				}
				img{
					width: 100%;
					margin-bottom: 10px;
				}
				h1{
					font-size: 18px;
					margin-bottom: 5px;
					text-align: center;
				}
				h3{
					font-size: 14px;
					text-align: center;
					margin-bottom: 40px;
					color: $primary;
				}
				.btn-see{
					display: block;
					text-align: center;
					position: absolute;
					bottom: 10px;
					left: 10px;
					width: calc(100% - 20px);
					border: 1px solid rgba($black, .1);
					color: $secondary;
					transition: .5s ease all;
					&:hover{
						color: $white;
						border-color: $secondary;
						background-color: $secondary;
						transition: .5s ease all;
					}
				}
			}
		}
	}
	.loadMore{
		width: 100%;
		text-align: center;
		button{
			border: 1px solid $primary;
			padding: 4px 15px;
			border-radius: 4px;
			background-color: $primary;
			color: $white;
			transition: .5s ease all;
			&:hover{
				background-color: $secondary;
				transition: .5s ease all;
			}
		}
	}
	.img-detail{
		width: 100%;
		margin-bottom: 30px;
	}
	.content-detail{
		h1{
			font-size: 24px;
			margin-bottom: 5px;
		}
		h3{
			font-size: 18px;
			margin-bottom: 15px;
			color: $primary;
		}
		.share-view{
			margin-top: 30px;
			ul{
				list-style: none;
				padding: 0;
				display: flex;
				li{
					padding: 0 5px;
					&.facebook{
						a{
							background: #3b5998;
						}
					}
					&.twitter{
						a{
							background: #00acee;
						}
					}
					&.whatsapp{
						a{
							background: #4FCE5D;
						}
					}
					&.view{
						a{
							background: $primary;
						}
					}
					a{
						display: flex;
						min-width: 70px;
						align-items: center;
						color: $white;
						padding: 0 2px;
						border-radius: 3px;
						font-size: 24px;
						.text{
							width: 100%;
							font-size: 10px;
							text-align: center;
						}
					}
					&:first-child{
						padding-left: 0;
					}
					&:last-child{
						padding-right: 0;
					}
				}
			}
		}
	}
}

footer{
    .top{
        background: $white url(../images/footer-bg.png) no-repeat right top;
        background-size: contain;
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
        padding: 60px 0 30px 0;
        .logo{
            display: flex;
            align-items:center;
            margin-bottom: 15px;
            img{
                height: 60px;
                margin-right: 7px;
            }
            .text{
                color: $primary;
                font-family: 'DM Serif Display', serif;
                transition: .1s ease all;
                h1{
                    margin: 0;
                    font-size: 18px;
                    font-weight: 900;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    transition: .5s ease all;
                }
            }
        }
        .socmed{
            display: flex;
            margin-bottom: 1rem;
            a{
                display: block;
                font-size: 26px;
                color: rgba($primary,.5);
                margin-right: 10px;
                transition: .5s ease all;
                &:hover{
                    color: rgba($primary,1);
                    transition: .5s ease all;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        h1{
            font-family: 'DM Serif Display', serif;
            font-size: 28px;
            color: $primary;
        }
        ul.contact{
            list-style: none;
            padding: 0;
            li{
                a{
                    display: block;
                    padding: 3px 0;
                    i{
                        width: 30px;
                        color: $secondary;
                        transition: .5s ease all;
                        &:hover{
                            color: $primary;
                            transition: .5s ease all;
                        }
                    }
                    span{
                        transition: .5s ease all;
                        &:hover{
                            color: $primary;
                            transition: .5s ease all;
                        }
                    }
                }
            }
        }
        ul.menu{
            padding: 0;
            list-style: none;
            li{
                list-style: disc inside;
                transition: .5s ease all;
                &::marker{
                    color: $secondary;
                }
                &:hover{
                    color: $primary;
                    transition: .5s ease all;
                }
            }
        }
    }
    .bottom{
        p{
            font-size: 12px;
            margin: 8px 0;
        }
    }
}