@import "color.scss";

@media (max-width: 1199.98px) {
    main{
        section.highlight{
            ul.prestasi{
                li{
                    .image{
                        width: 33%;
                        max-width: 100%;
                    }
                    .text{
                        width: 67%;
                    }
                }
            }
        }
    }
}

@media (max-width: 991.98px) {
    main{
        header{
            &.bg-solid{
                .menu{
                    ul{
                        li{
                            a{
                                color: $white;
                            }
                        }
                    }
                }
            }
            .menu{
                position: fixed;
                top: 90px;
                right: -100%;
                background: $primary;
                width: 100%;
                height: 100%;
                overflow: auto;
                transition: .5s ease all;
                ul{
                    flex-direction: column;
                    color: $white;
                    padding: 0 0 120px 0;
                    li{
                        a{
                            padding: 15px 13px;
                        }
                        &:has(ul){
                            display: flex;
                            flex-wrap: wrap;
                            a{
                                width: 100%;
                                &:hover{
                                    color: $secondary;
                                }
                                flex: 50%;
                            }
                            span{
                                padding: 15px 28px;
                                cursor: pointer;
                                &:hover{
                                    color: $secondary;
                                }
                            }
                        }
                        &:hover{
                            a{
                                color: $white;
                            }
                            ul{
                                display: none;
                            }
                        }
                        ul{
                            position: relative;
                            background: $primary2;
                            padding: 0;
                            li{
                                a{
                                    &:hover{
                                        color: $secondary;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        section.highlight{
            ul.prestasi{
                li{
                    .image{
                        width: 50%;
                        max-width: 100%;
                    }
                    .text{
                        width: 50%;
                    }
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    main{
        section.highlight{
            ul.prestasi{
                li{
                    .image{
                        width: 40%;
                        max-width: 100%;
                    }
                    .text{
                        width: 60%;
                    }
                }
            }
        }
    }
}

@media (max-width: 575.98px) {
    main{
        section.slider{
            .swiper-container{
                .vertical-info{
                    left: 32px;
                }
                .swiper-wrapper{
                    &:before{
                        left: 32px;
                        width: 40px;
                    }
                    .swiper-slide{
                        padding-right: 25px;
                        padding-left: 80px;
                        .description{
                            width: 100%;
                            h1{
                                font-size: 32px;
                                line-height: 32px;
                            }
                            h2{
                                font-size: 28px;
                                line-height: 28px;
                            }
                        }
                    }
                }
                .swiper-pagination{
                    left: 80px;
                }
                .swiper-navigation{
                    right: 25px;
                }
            }
        }
    }
}