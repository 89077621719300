@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");
.cage-nav {
  height: 50px;
  width: 50px;
  padding: 14px 10px;
  position: relative;
  transition: 0.5s ease all;
}
.cage-nav .navTrigger {
  cursor: pointer;
  width: 30px;
  height: 21px;
  transform: rotate(180deg);
}
.cage-nav .navTrigger i {
  background-color: #FFF;
  border-radius: 2px;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  transition: 0.5s ease all;
}
.cage-nav .navTrigger i:nth-child(1) {
  -webkit-animation: outT 0.8s backwards;
  animation: outT 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  width: 21px;
}
.cage-nav .navTrigger i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.8s backwards;
  animation: outM 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}
.cage-nav .navTrigger i:nth-child(3) {
  -webkit-animation: outBtm 0.8s backwards;
  animation: outBtm 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}
.cage-nav .navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
  width: 100%;
}
.cage-nav .navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
  width: 100%;
}
.cage-nav .navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}
@keyframes inM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
  }
}
@keyframes outM {
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}
@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}
@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}
@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(9px) rotate(0deg);
  }
  100% {
    transform: translateY(9px) rotate(135deg);
  }
}
@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}
@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}
@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }
  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}
@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-9px) rotate(0deg);
  }
  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}
@media (max-width: 1199.98px) {
  main section.highlight ul.prestasi li .image {
    width: 33%;
    max-width: 100%;
  }
  main section.highlight ul.prestasi li .text {
    width: 67%;
  }
}
@media (max-width: 991.98px) {
  main header.bg-solid .menu ul li a {
    color: #FFF;
  }
  main header .menu {
    position: fixed;
    top: 90px;
    right: -100%;
    background: #016315;
    width: 100%;
    height: 100%;
    overflow: auto;
    transition: 0.5s ease all;
  }
  main header .menu ul {
    flex-direction: column;
    color: #FFF;
    padding: 0 0 120px 0;
  }
  main header .menu ul li a {
    padding: 15px 13px;
  }
  main header .menu ul li:has(ul) {
    display: flex;
    flex-wrap: wrap;
  }
  main header .menu ul li:has(ul) a {
    width: 100%;
    flex: 50%;
  }
  main header .menu ul li:has(ul) a:hover {
    color: #f16c2a;
  }
  main header .menu ul li:has(ul) span {
    padding: 15px 28px;
    cursor: pointer;
  }
  main header .menu ul li:has(ul) span:hover {
    color: #f16c2a;
  }
  main header .menu ul li:hover a {
    color: #FFF;
  }
  main header .menu ul li:hover ul {
    display: none;
  }
  main header .menu ul li ul {
    position: relative;
    background: #01410e;
    padding: 0;
  }
  main header .menu ul li ul li a:hover {
    color: #f16c2a;
  }
  main section.highlight ul.prestasi li .image {
    width: 50%;
    max-width: 100%;
  }
  main section.highlight ul.prestasi li .text {
    width: 50%;
  }
}
@media (max-width: 767.98px) {
  main section.highlight ul.prestasi li .image {
    width: 40%;
    max-width: 100%;
  }
  main section.highlight ul.prestasi li .text {
    width: 60%;
  }
}
@media (max-width: 575.98px) {
  main section.slider .swiper-container .vertical-info {
    left: 32px;
  }
  main section.slider .swiper-container .swiper-wrapper:before {
    left: 32px;
    width: 40px;
  }
  main section.slider .swiper-container .swiper-wrapper .swiper-slide {
    padding-right: 25px;
    padding-left: 80px;
  }
  main section.slider .swiper-container .swiper-wrapper .swiper-slide .description {
    width: 100%;
  }
  main section.slider .swiper-container .swiper-wrapper .swiper-slide .description h1 {
    font-size: 32px;
    line-height: 32px;
  }
  main section.slider .swiper-container .swiper-wrapper .swiper-slide .description h2 {
    font-size: 28px;
    line-height: 28px;
  }
  main section.slider .swiper-container .swiper-pagination {
    left: 80px;
  }
  main section.slider .swiper-container .swiper-navigation {
    right: 25px;
  }
}
html {
  height: -webkit-fill-available;
}

body {
  font-family: "Jost", sans-serif;
  letter-spacing: 0.5px;
  cursor: context-menu;
  overflow-x: hidden;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
body main {
  width: 100%;
  overflow: hidden;
}
body.overflow-y-hidden {
  overflow-y: hidden !important;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none !important;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
a:visited {
  text-decoration: none;
  color: inherit;
}
a:link {
  text-decoration: none;
  color: inherit;
}
a:active {
  text-decoration: none;
  color: inherit;
  outline: none !important;
  box-shadow: none;
}
a.page-link:focus {
  box-shadow: none;
}

.btn {
  outline: none !important;
}
.btn:focus, .btn:active {
  outline: 0 !important;
  box-shadow: none !important;
}
.btn.show {
  background-color: #016315;
  color: #FFF !important;
  border-color: #016315;
}

.btn-outline-primary {
  border-color: #016315;
  background-color: transparent;
  color: #016315 !important;
  font-size: 14px;
}
.btn-outline-primary:hover {
  background-color: #016315;
  color: #FFF !important;
  border-color: #016315;
}
.btn-outline-primary:active {
  background-color: #016315 !important;
  color: #FFF !important;
  border-color: #016315 !important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active .show > .btn-outline-primary.dropdown-toggle {
  border-color: #016315;
  background-color: transparent;
  color: #016315;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:hover, .btn-outline-primary:not(:disabled):not(.disabled):active .show > .btn-outline-primary.dropdown-toggle:hover {
  background-color: #016315;
  color: #FFF !important;
  border-color: #016315;
}

.form-control {
  outline: none !important;
}
.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  padding: 15px 0;
}
header.bg-solid {
  position: fixed;
  background: rgba(255, 255, 255, 0.95);
  transition: 0.5s ease all;
}
header.bg-solid .logo img {
  height: 40px;
  transition: 0.5s ease all;
}
header.bg-solid .logo .text {
  color: #000;
  transition: 0.1s ease all;
}
header.bg-solid .logo .text h1 {
  font-size: 12px;
  transition: 0.5s ease all;
}
header.bg-solid .menu {
  top: 70px;
}
header.bg-solid .menu ul li a {
  font-size: 14px;
  color: #000;
  transition: 0.5s ease all;
}
header.bg-solid .cage-nav {
  height: 35px;
  width: 35px;
  padding: 0;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
}
header.bg-solid .cage-nav .navTrigger {
  width: 28px;
}
header.bg-solid .cage-nav .navTrigger i {
  background-color: #000;
  height: 4px;
  transition: 0.5s ease all;
}
header.page {
  position: relative;
  background: #FFF;
}
header .logo {
  display: flex;
  align-items: center;
}
header .logo img {
  height: 60px;
  margin-right: 7px;
}
header .logo .text {
  color: #FFF;
  font-family: "DM Serif Display", serif;
  transition: 0.1s ease all;
}
header .logo .text h1 {
  margin: 0;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.5s ease all;
}
header .logo .text h2 {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  transition: 0.5s ease all;
}
header .menu .container {
  padding: 0;
}
header .menu ul {
  display: flex;
  list-style: none;
  margin: 0;
}
header .menu ul li {
  position: relative;
  z-index: 0;
}
header .menu ul li.active a {
  color: #f16c2a;
}
header .menu ul li a {
  color: #FFF;
  display: block;
  padding: 8px 15px;
  transition: 0.5s ease all;
}
header .menu ul li ul {
  display: none;
  position: absolute;
  background: #016315;
  color: #FFF;
  left: 0;
  top: calc(100% - 2.5px);
  width: 100%;
  min-width: 180px;
}
header .menu ul li:hover a {
  color: #016315;
  transition: 0.5s ease all;
}
header .menu ul li:hover ul {
  display: block;
  z-index: 2;
  padding-left: 0;
}
header .menu ul li:hover ul li a {
  color: #FFF;
}
header .menu ul li:hover ul li a:hover {
  color: #f16c2a;
}
header .menu.mobile-active {
  right: 0;
  transition: 0.5s ease all;
}

section.header-page {
  position: relative;
  width: 100%;
  height: 40vh;
  background: url("../images/bgparallax.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: 2px solid #016315;
}
section.header-page:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
}
section.header-page:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
section.header-page .text {
  position: absolute;
  bottom: 0;
  z-index: 2;
  color: #FFF;
}
section.header-page .text h1 {
  font-family: "DM Serif Display", serif;
}

section.page {
  padding: 60px 0;
}
section.page .sideMenu {
  background: #016315;
  padding: 5px 15px 15px;
  border-radius: 3px;
}
section.page .sideMenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
section.page .sideMenu ul li a {
  display: block;
  padding: 10px 15px;
  color: #FFF;
  border-bottom: 1px solid #FFF;
}
section.page .sideMenu ul li.active a {
  color: #f16c2a;
  border-color: #f16c2a;
}
section.page .sideMenu ul li:hover {
  transition: 0.5s ease all;
}
section.page .sideMenu ul li:hover a {
  color: #f16c2a;
  border-color: #f16c2a;
  transition: 0.5s ease all;
}
section.page .share-view {
  margin-top: 30px;
}
section.page .share-view ul {
  list-style: none;
  padding: 0;
  display: flex;
}
section.page .share-view ul li {
  padding: 0 5px;
}
section.page .share-view ul li.facebook a {
  background: #3b5998;
}
section.page .share-view ul li.twitter a {
  background: #00acee;
}
section.page .share-view ul li.whatsapp a {
  background: #4FCE5D;
}
section.page .share-view ul li.view a {
  background: #016315;
}
section.page .share-view ul li a {
  display: flex;
  min-width: 70px;
  align-items: center;
  color: #FFF;
  padding: 0 2px;
  border-radius: 3px;
  font-size: 24px;
}
section.page .share-view ul li a .text {
  width: 100%;
  font-size: 10px;
  text-align: center;
}
section.page .share-view ul li:first-child {
  padding-left: 0;
}
section.page .share-view ul li:last-child {
  padding-right: 0;
}
section.page .newsPopular h1 {
  font-size: 18px;
  margin-bottom: 25px;
}
section.page .newsPopular ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
section.page .newsPopular ul li {
  display: flex;
  margin-bottom: 18px;
}
section.page .newsPopular ul li .image {
  width: 30%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 3px;
}
section.page .newsPopular ul li .text {
  width: 70%;
  padding-left: 10px;
}
section.page .newsPopular ul li .text h3 {
  font-size: 14px;
  line-height: 1.5;
  max-width: 100%;
  max-height: 100%;
  white-space: pre-wrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
  min-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
section.page .newsPopular ul li .text a {
  font-size: 12px;
  display: block;
  color: #f16c2a;
  transition: 0.5s ease all;
}
section.page .newsPopular ul li .text a:hover {
  color: #016315;
  transition: 0.5s ease all;
}
section.page .anounNew h1 {
  font-size: 18px;
  margin-bottom: 25px;
}
section.page .anounNew ul {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
}
section.page .anounNew ul li {
  background: #F0F0F0;
  position: relative;
  padding: 15px 10px 15px 100px;
  margin-top: 10px;
  margin-bottom: 30px;
}
section.page .anounNew ul li h4 {
  font-size: 14px;
  font-weight: bold;
}
section.page .anounNew ul li h4:hover {
  color: #016315;
}
section.page .anounNew ul li p {
  font-size: 12px;
  margin: 0 0 8px 0;
}
section.page .anounNew ul li .btn-read {
  font-size: 12px;
}
section.page .anounNew ul li .btn-read:hover {
  color: #016315;
}
section.page .anounNew ul li .date {
  position: absolute;
  left: 10px;
  top: -10px;
  background: #016315;
  width: 80px;
  height: 80%;
  padding: 10px;
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
}
section.page .anounNew ul li .date:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 5px solid black;
  position: absolute;
  top: 0;
  left: -10px;
  border-left-color: transparent;
  border-top-color: transparent;
}
section.page .anounNew ul li .date .box {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
}
section.page .anounNew ul li .date .box h3 {
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
section.page .anounNew ul li .date .box h3 span {
  display: block;
  font-size: 24px;
}
section.page ul.newsList {
  list-style: none;
  padding: 0;
}
section.page ul.newsList li {
  display: flex;
  margin-bottom: 30px;
}
section.page ul.newsList li .image {
  width: 30%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 3px;
  background-color: #016315;
}
section.page ul.newsList li .text {
  width: 70%;
  min-height: 100px;
  padding-left: 10px;
}
section.page ul.newsList li .text h3 {
  font-size: 14px;
  line-height: 1.5;
  max-width: 100%;
  max-height: 100%;
  white-space: pre-wrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
  min-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: bold;
}
section.page ul.newsList li .text p {
  font-size: 14px;
  line-height: 1.5;
  max-width: 100%;
  max-height: 100%;
  white-space: pre-wrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
  min-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
section.page ul.newsList li .text p.name {
  color: #016315;
}
section.page ul.newsList li .text a {
  font-size: 12px;
  display: block;
  color: #f16c2a;
  transition: 0.5s ease all;
}
section.page ul.newsList li .text a:hover {
  color: #016315;
  transition: 0.5s ease all;
}
section.page .loadMore {
  width: 100%;
  text-align: center;
}
section.page .loadMore button {
  border: 1px solid #016315;
  padding: 4px 15px;
  border-radius: 4px;
  background-color: #016315;
  color: #FFF;
  transition: 0.5s ease all;
}
section.page .loadMore button:hover {
  background-color: #f16c2a;
  transition: 0.5s ease all;
}
section.page ul.anounList {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
}
section.page ul.anounList li {
  background: #F0F0F0;
  position: relative;
  padding: 15px 10px 15px 100px;
  margin-top: 10px;
  margin-bottom: 30px;
}
section.page ul.anounList li h4 {
  font-size: 14px;
  font-weight: bold;
}
section.page ul.anounList li h4:hover {
  color: #016315;
}
section.page ul.anounList li p {
  font-size: 12px;
  margin: 0 0 8px 0;
}
section.page ul.anounList li .btn-read {
  font-size: 14px;
}
section.page ul.anounList li .btn-read:hover {
  color: #016315;
}
section.page ul.anounList li .date {
  position: absolute;
  left: 10px;
  top: -10px;
  background: #016315;
  width: 80px;
  height: 80%;
  padding: 10px;
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
}
section.page ul.anounList li .date:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 5px solid black;
  position: absolute;
  top: 0;
  left: -10px;
  border-left-color: transparent;
  border-top-color: transparent;
}
section.page ul.anounList li .date .box {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
}
section.page ul.anounList li .date .box h3 {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
section.page ul.anounList li .date .box h3 span {
  display: block;
  font-size: 32px;
}
section.page ul.extraList {
  list-style: none;
  padding: 0;
}
section.page ul.extraList li .content {
  border: 1px solid #016315;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.2);
  transition: 0.5s ease all;
  position: relative;
}
section.page ul.extraList li .content:before {
  content: "";
  background: linear-gradient(135deg, #016315 70%, #f16c2a 100%);
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.5s ease all;
}
section.page ul.extraList li .content:hover {
  transition: 0.5s ease all;
  color: #FFF;
}
section.page ul.extraList li .content:hover:before {
  left: 0;
  transition: 0.5s ease all;
}
section.page ul.extraList li .content .image {
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
section.page ul.extraList li .content h1.title {
  font-size: 24px;
  transition: 0.5s ease all;
}
section.page ul.extraList li .content h1.title:hover {
  color: #f16c2a;
  transition: 0.5s ease all;
}
section.page ul.extraList li .content p {
  font-size: 14px;
  line-height: 1.5;
  max-width: 100%;
  max-height: 100%;
  white-space: pre-wrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
  min-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
section.page ul.extraList li .content .btn-link i {
  transition: 0.5s ease all;
}
section.page ul.extraList li .content .btn-link:hover {
  color: #FFF;
}
section.page ul.extraList li .content .btn-link:hover i {
  transition: 0.5s ease all;
  color: #f16c2a;
}
section.page ul.listImage {
  list-style: none;
  padding: 0;
}
section.page ul.listImage li {
  cursor: pointer;
}

section.contact {
  padding: 60px 0;
}
section.contact h1 {
  font-size: 24px;
}
section.contact .btn-submit {
  padding: 4px 15px;
  background: #016315;
  color: #FFF;
  border: 1px solid #016315;
  border-radius: 4px;
  transition: 0.5s ease all;
}
section.contact .btn-submit:hover {
  background: #f16c2a;
  transition: 0.5s ease all;
}
section.contact ul.contact {
  list-style: none;
  padding: 0;
}
section.contact ul.contact li a {
  display: block;
  padding: 3px 0;
}
section.contact ul.contact li a i {
  width: 30px;
  color: #f16c2a;
  transition: 0.5s ease all;
}
section.contact ul.contact li a i:hover {
  color: #016315;
  transition: 0.5s ease all;
}
section.contact ul.contact li a span {
  transition: 0.5s ease all;
}
section.contact ul.contact li a span:hover {
  color: #016315;
  transition: 0.5s ease all;
}

section.slider .swiper-container {
  width: 100%;
  height: 100vh;
  background: #016315;
  position: relative;
}
section.slider .swiper-container .swiper-wrapper:before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 40px;
  left: 80px;
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.9);
}
section.slider .swiper-container .swiper-wrapper .swiper-slide {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  padding: 100px 40px 100px 100px;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide:before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  z-index: 2;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide:after {
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  z-index: 2;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide:nth-child(odd) {
  text-align: left;
  justify-content: flex-start;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide:nth-child(even) {
  text-align: right;
  justify-content: flex-end;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide .description {
  position: relative;
  z-index: 3;
  width: 70%;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide .description .head-text {
  font-style: italic;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 15px;
  font-weight: 300;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide .description h1 {
  color: #f16c2a;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 64px;
  line-height: 60px;
  margin-bottom: 5px;
  font-family: "DM Serif Display", serif;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide .description h2 {
  color: #FFF;
  font-weight: 300;
  font-size: 46px;
  line-height: 46px;
  margin-bottom: 15px;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide .description .btn-slide {
  background: #016315;
  border: 1px solid #f16c2a;
  color: #FFF;
  padding: 4px 25px;
  border-radius: 15px;
  margin-bottom: 15px;
  transition: 0.5s ease all;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide .description .btn-slide:hover {
  transition: 0.5s ease all;
  opacity: 0.8;
}
section.slider .swiper-container .swiper-wrapper .swiper-slide .description .foot-text {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 2px;
  font-weight: 300;
}
section.slider .swiper-container .vertical-info {
  position: absolute;
  top: 50%;
  left: 50px;
  padding: 10px 100px 10px 50px;
  z-index: 3;
  width: 100vh;
  transform: translate(-50%, -50%) rotate(270deg);
  display: flex;
  justify-content: space-between;
}
section.slider .swiper-container .vertical-info .socmed a {
  display: inline-block;
  padding-left: 15px;
  color: rgba(255, 255, 255, 0.9);
}
section.slider .swiper-container .vertical-info .socmed a:first-child() {
  padding-left: 0;
}
section.slider .swiper-container .vertical-info .socmed a i {
  transform: rotate(90deg);
}
section.slider .swiper-container .vertical-info:after {
  content: "Ikuti Kami";
  text-transform: uppercase;
  letter-spacing: 5px;
  position: absolute;
  top: 50%;
  left: calc(45% + 30vh);
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.9);
}
section.slider .swiper-container .vertical-info:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 30vh;
  height: 1px;
  background: rgba(255, 255, 255, 0.9);
}
section.slider .swiper-container .swiper-pagination {
  bottom: 20px;
  left: 200px;
  height: 50px;
  width: 50px;
  color: #FFF;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
}
section.slider .swiper-container .swiper-pagination .swiper-pagination-current {
  font-size: 52px;
  line-height: 52px;
  color: #f16c2a;
}
section.slider .swiper-container .swiper-navigation {
  position: absolute;
  z-index: 3;
  bottom: 20px;
  right: 45px;
  color: #FFF;
  display: flex;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
}
section.slider .swiper-container .swiper-navigation .slide-button-prev {
  margin-right: 15px;
}
section.slider .swiper-container .swiper-navigation .slide-button-prev.swiper-button-disabled {
  opacity: 0.7;
}
section.slider .swiper-container .swiper-navigation .slide-button-next {
  margin-left: 15px;
}
section.slider .swiper-container .swiper-navigation .slide-button-next.swiper-button-disabled {
  opacity: 0.7;
}

section.intro {
  background-image: url("../images/pattern.png");
  background-position: center center;
  background-size: auto 100%;
  padding: 60px 0;
}
section.intro .content {
  position: relative;
}
section.intro .content h1.title {
  font-family: "DM Serif Display", serif;
  font-size: 24px;
}
section.intro .image {
  width: calc(100% - 30px);
  margin: 15px;
  height: calc(100% - 30px);
  min-height: 450px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  border-radius: 30px 30px 0 30px;
  position: relative;
  z-index: 3;
}
section.intro .image:before {
  content: "";
  position: absolute;
  bottom: -15px;
  right: -15px;
  border-radius: 0 15px 15px 0;
  width: 15px;
  height: 50%;
  background: #016315;
  z-index: -1;
}
section.intro .image:after {
  content: "";
  position: absolute;
  bottom: -15px;
  right: -15px;
  border-radius: 0 0 15px 15px;
  width: 100%;
  height: 15px;
  background: #016315;
  z-index: -1;
}

section.smartSchool {
  margin: 60px 0 0;
  padding: 90px 0;
  background-image: url("../images/bgparallax.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
}
section.smartSchool::before {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}
section.smartSchool h1.title {
  font-family: "DM Serif Display", serif;
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
}
section.smartSchool .linkCard {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  background: rgba(1, 99, 21, 0.9);
  color: #FFF;
  text-align: center;
  justify-content: center;
  border-radius: 4px;
  padding: 15px;
  height: 100%;
  overflow: hidden;
}
section.smartSchool .linkCard::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-image: url("../images/logo.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 50%;
  z-index: -1;
  opacity: 0.1;
}
section.smartSchool .linkCard h1 {
  font-size: 22px;
}
section.smartSchool .linkCard p {
  font-size: 12px;
  margin-bottom: 0;
}
section.smartSchool .linkCard.disabled {
  filter: grayscale(1);
  pointer-events: none;
}

section.highlight {
  padding: 60px 0;
}
section.highlight h1.title {
  font-family: "DM Serif Display", serif;
  font-size: 32px;
}
section.highlight a.btn-another p {
  margin-bottom: 48px;
  color: #016315;
}
section.highlight ul.prestasi {
  list-style: none;
  padding: 0 0 30px 0;
  margin: 0;
}
section.highlight ul.prestasi li {
  position: relative;
  display: flex;
  background-color: #F0F0F0;
  margin-bottom: 25px;
  transition: 0.5s ease all;
}
section.highlight ul.prestasi li::before {
  content: "";
  position: absolute;
  background: #016315;
  top: -10px;
  height: 10px;
  width: 80%;
  border-radius: 0 20px 0 0;
  z-index: 2;
}
section.highlight ul.prestasi li::after {
  content: "";
  position: absolute;
  background: #01410e;
  top: -10px;
  left: calc(80% - 10px);
  height: 10px;
  width: 20px;
  border-radius: 0 20px 0 0;
}
section.highlight ul.prestasi li .image {
  width: 23%;
}
section.highlight ul.prestasi li .text {
  width: 77%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.highlight ul.prestasi li .text .title {
  font-weight: bold;
  font-size: 14px;
}
section.highlight ul.prestasi li .text .winner {
  color: #f16c2a;
  margin-bottom: 8px;
  font-size: 13px;
}
section.highlight ul.prestasi li a {
  font-size: 14px;
}
section.highlight ul.prestasi li a:hover {
  color: #016315;
}
section.highlight ul.pengumuman {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
}
section.highlight ul.pengumuman li {
  background: #F0F0F0;
  position: relative;
  padding: 15px 10px 15px 100px;
  margin-top: 10px;
  margin-bottom: 30px;
}
section.highlight ul.pengumuman li h4 {
  font-size: 14px;
  font-weight: bold;
}
section.highlight ul.pengumuman li h4:hover {
  color: #016315;
}
section.highlight ul.pengumuman li p {
  font-size: 12px;
  margin: 0 0 8px 0;
}
section.highlight ul.pengumuman li .btn-read {
  font-size: 14px;
}
section.highlight ul.pengumuman li .btn-read:hover {
  color: #016315;
}
section.highlight ul.pengumuman li .date {
  position: absolute;
  left: 10px;
  top: -10px;
  background: #016315;
  width: 80px;
  height: 80%;
  padding: 10px;
  text-transform: uppercase;
  color: #FFF;
  text-align: center;
}
section.highlight ul.pengumuman li .date:before {
  content: "";
  width: 10px;
  height: 10px;
  border: 5px solid black;
  position: absolute;
  top: 0;
  left: -10px;
  border-left-color: transparent;
  border-top-color: transparent;
}
section.highlight ul.pengumuman li .date .box {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
}
section.highlight ul.pengumuman li .date .box h3 {
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
section.highlight ul.pengumuman li .date .box h3 span {
  display: block;
  font-size: 32px;
}

section.news {
  padding: 60px 0;
}
section.news h1.title {
  font-family: "DM Serif Display", serif;
  font-size: 32px;
}
section.news .content {
  position: relative;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  border-radius: 15px;
  height: 100%;
  transition: 0.5s ease all;
}
section.news .content:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.4);
  transition: 0.5s ease all;
}
section.news .content .image {
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  position: relative;
}
section.news .content .image .date {
  background: #016315;
  display: inline-block;
  color: #FFF;
  padding: 3px 8px;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
  left: 15px;
}
section.news .content .description {
  padding: 15px;
}
section.news .content .description h1.title {
  font-size: 24px;
  transition: 0.5s ease all;
}
section.news .content .description h1.title:hover {
  color: #f16c2a;
  transition: 0.5s ease all;
}
section.news .content .description p {
  margin-bottom: 40px;
}
section.news .content .description .btn-link {
  position: absolute;
  left: 15px;
  bottom: 15px;
}
section.news .content .description .btn-link i {
  transition: 0.5s ease all;
}
section.news .content .description .btn-link:hover {
  color: #016315;
}
section.news .content .description .btn-link:hover i {
  transition: 0.5s ease all;
  color: #f16c2a;
}

section.extra {
  padding: 60px 0;
}
section.extra h1.title {
  font-family: "DM Serif Display", serif;
  font-size: 32px;
}
section.extra .content {
  border: 1px solid #016315;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.2);
  transition: 0.5s ease all;
  position: relative;
}
section.extra .content:before {
  content: "";
  background: linear-gradient(135deg, #016315 70%, #f16c2a 100%);
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: 0.5s ease all;
}
section.extra .content:hover {
  transition: 0.5s ease all;
  color: #FFF;
}
section.extra .content:hover:before {
  left: 0;
  transition: 0.5s ease all;
}
section.extra .content .image {
  width: 100%;
  height: 100%;
  min-height: 100px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
section.extra .content h1.title {
  font-size: 24px;
  transition: 0.5s ease all;
}
section.extra .content h1.title:hover {
  color: #f16c2a;
  transition: 0.5s ease all;
}
section.extra .content .btn-link i {
  transition: 0.5s ease all;
}
section.extra .content .btn-link:hover {
  color: #FFF;
}
section.extra .content .btn-link:hover i {
  transition: 0.5s ease all;
  color: #f16c2a;
}

section.gallery {
  padding: 60px 0;
}
section.gallery h1.title {
  font-family: "DM Serif Display", serif;
  font-size: 32px;
}
section.gallery .galeriSlide .swiper-wrapper .swiper-slide {
  display: block;
  overflow: hidden;
  border-radius: 8px;
}
section.gallery .loadMore {
  width: 100%;
  text-align: center;
}
section.gallery .loadMore button {
  border: 1px solid #016315;
  padding: 4px 15px;
  border-radius: 4px;
  background-color: #016315;
  color: #FFF;
  transition: 0.5s ease all;
}
section.gallery .loadMore button:hover {
  background-color: #f16c2a;
  transition: 0.5s ease all;
}

section.ppdb {
  padding: 60px 0;
}
section.ppdb .content {
  background-color: #016315;
  text-align: center;
  padding: 60px 15px;
  color: #FFF;
  border-radius: 30px;
}
section.ppdb .content h1.title {
  font-family: "DM Serif Display", serif;
  font-size: 32px;
}
section.ppdb .content .btn-link {
  border: 1px solid #FFF;
  background-color: rgba(255, 255, 255, 0.08);
}
section.ppdb .content .btn-link:hover {
  background-color: #f16c2a;
  color: #FFF;
}

section.staff {
  padding: 60px 0;
}
section.staff ul {
  list-style: none;
  padding: 0;
}
section.staff ul li .content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.5s ease all;
}
section.staff ul li .content:hover {
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.4);
  transition: 0.5s ease all;
}
section.staff ul li .content img {
  width: 100%;
  margin-bottom: 10px;
}
section.staff ul li .content h1 {
  font-size: 18px;
  margin-bottom: 5px;
  text-align: center;
}
section.staff ul li .content h3 {
  font-size: 14px;
  text-align: center;
  margin-bottom: 40px;
  color: #016315;
}
section.staff ul li .content .btn-see {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #f16c2a;
  transition: 0.5s ease all;
}
section.staff ul li .content .btn-see:hover {
  color: #FFF;
  border-color: #f16c2a;
  background-color: #f16c2a;
  transition: 0.5s ease all;
}
section.staff .loadMore {
  width: 100%;
  text-align: center;
}
section.staff .loadMore button {
  border: 1px solid #016315;
  padding: 4px 15px;
  border-radius: 4px;
  background-color: #016315;
  color: #FFF;
  transition: 0.5s ease all;
}
section.staff .loadMore button:hover {
  background-color: #f16c2a;
  transition: 0.5s ease all;
}
section.staff .img-detail {
  width: 100%;
  margin-bottom: 30px;
}
section.staff .content-detail h1 {
  font-size: 24px;
  margin-bottom: 5px;
}
section.staff .content-detail h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #016315;
}
section.staff .content-detail .share-view {
  margin-top: 30px;
}
section.staff .content-detail .share-view ul {
  list-style: none;
  padding: 0;
  display: flex;
}
section.staff .content-detail .share-view ul li {
  padding: 0 5px;
}
section.staff .content-detail .share-view ul li.facebook a {
  background: #3b5998;
}
section.staff .content-detail .share-view ul li.twitter a {
  background: #00acee;
}
section.staff .content-detail .share-view ul li.whatsapp a {
  background: #4FCE5D;
}
section.staff .content-detail .share-view ul li.view a {
  background: #016315;
}
section.staff .content-detail .share-view ul li a {
  display: flex;
  min-width: 70px;
  align-items: center;
  color: #FFF;
  padding: 0 2px;
  border-radius: 3px;
  font-size: 24px;
}
section.staff .content-detail .share-view ul li a .text {
  width: 100%;
  font-size: 10px;
  text-align: center;
}
section.staff .content-detail .share-view ul li:first-child {
  padding-left: 0;
}
section.staff .content-detail .share-view ul li:last-child {
  padding-right: 0;
}

footer .top {
  background: #FFF url(../images/footer-bg.png) no-repeat right top;
  background-size: contain;
  border-top: 1px solid #016315;
  border-bottom: 1px solid #016315;
  padding: 60px 0 30px 0;
}
footer .top .logo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
footer .top .logo img {
  height: 60px;
  margin-right: 7px;
}
footer .top .logo .text {
  color: #016315;
  font-family: "DM Serif Display", serif;
  transition: 0.1s ease all;
}
footer .top .logo .text h1 {
  margin: 0;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.5s ease all;
}
footer .top .socmed {
  display: flex;
  margin-bottom: 1rem;
}
footer .top .socmed a {
  display: block;
  font-size: 26px;
  color: rgba(1, 99, 21, 0.5);
  margin-right: 10px;
  transition: 0.5s ease all;
}
footer .top .socmed a:hover {
  color: #016315;
  transition: 0.5s ease all;
}
footer .top .socmed a:last-child {
  margin-right: 0;
}
footer .top h1 {
  font-family: "DM Serif Display", serif;
  font-size: 28px;
  color: #016315;
}
footer .top ul.contact {
  list-style: none;
  padding: 0;
}
footer .top ul.contact li a {
  display: block;
  padding: 3px 0;
}
footer .top ul.contact li a i {
  width: 30px;
  color: #f16c2a;
  transition: 0.5s ease all;
}
footer .top ul.contact li a i:hover {
  color: #016315;
  transition: 0.5s ease all;
}
footer .top ul.contact li a span {
  transition: 0.5s ease all;
}
footer .top ul.contact li a span:hover {
  color: #016315;
  transition: 0.5s ease all;
}
footer .top ul.menu {
  padding: 0;
  list-style: none;
}
footer .top ul.menu li {
  list-style: disc inside;
  transition: 0.5s ease all;
}
footer .top ul.menu li::marker {
  color: #f16c2a;
}
footer .top ul.menu li:hover {
  color: #016315;
  transition: 0.5s ease all;
}
footer .bottom p {
  font-size: 12px;
  margin: 8px 0;
}